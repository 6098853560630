import React from 'react';
import { Link } from 'gatsby';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Seo from '../components/seo';
import { HeroLayout } from '../components/layout';
import ContentBox from '../components/ContentBox';
import MainBox from '../components/MainBox';
import Hero from '../components/hero';

const IndexPage = () => (
  <HeroLayout>
    <Hero />
    <Seo
      title="SJGAR stack - the innovation toolbox"
      description="A software solution stack for creating modern apps on web and mobile
          that embraces cloud and apis. The stack is a collection of widely used
          technologies where each part comes with a vibrant community. As a
          whole because of this the stack comes with a large full stack talent
          pool. Pronounce sugar stack."
    />
    <MainBox maxWidth={800}>
      <Box pb={6}>
        {/* <p>
          <i>
            This is a prelease version. In the spirit of gathering customer
            feedback as soon as possible I am launching this site before it is
            'ready'. Any feedback, compliment, criticism or thought you might
            have is more than welcome. Please contact me at my gmail address
            which is a rather boring combination of my first and last name.
          </i>
        </p>
        <p style={{ marginBottom: 32 }}>
          <i>Hope to hear from you. Kind regards, Vijai Ramcharan</i>
        </p> */}

        <Typography variant="h1">SJGAR stack</Typography>
        <Typography variant="body" style={{ marginLeft: 0 }}>
          /pronounce sugar stack/
        </Typography>
      </Box>
      <ContentBox>
        <Typography variant="h3">elements of the SJGAR stack</Typography>
        <ul>
          <li>
            <Link to="/serverless">
              <strong>S</strong>erverless
            </Link>
          </li>
          <li>
            <Link to="/javascript-and-typescript">
              <strong>J</strong>avaScript / TypeScript
            </Link>
          </li>
          <li>
            <Link to="/graphql">
              <strong>G</strong>raphQL
            </Link>
          </li>
          <li>
            <Link to="/aws">
              <strong>A</strong>WS
            </Link>
          </li>
          <li>
            <Link to="/react">
              <strong>R</strong>eact
            </Link>
          </li>
        </ul>
      </ContentBox>
      <ContentBox>
        <Typography variant="h3">summary</Typography>
        <p>
          A software solution stack for creating modern apps on web and mobile
          that embraces cloud and apis. The stack is a collection of widely used
          technologies where each part comes with a vibrant community. As a
          whole because of this the stack comes with a large full stack talent
          pool.
        </p>
      </ContentBox>
      <ContentBox>
        <Typography variant="h3">why</Typography>
        <p>
          The above summary touches on some of the whys. For a more in depth
          story read the{' '}
          <Link to="/blog/20201028/introducing-the-sjgar-stack">
            <b>introducing the SJGAR stack</b>
          </Link>{' '}
          post. "Focus on your customers, on business value and the developer
          experience. Do more, and keep things simple."
        </p>
      </ContentBox>
      {/* Business value: https://martinfowler.com/articles/value-architectural-attribute.html#IfYoureMovingToTheCloudTakeJustTheValuableBits */}
      {/* Challenges: monitoring and correlating business logic logs, debugging */}

      <ContentBox>
        <Typography variant="h3">noun usage examples</Typography>
        <ol>
          <li>
            As a full stack engineer I recently have been working mostly with
            the SJGAR stack.
          </li>
          <li>
            We use the SJGAR stack, infra-as-code and CI/CD for our innovation
            projects.
          </li>
          <li>
            Our teams use the SJGAR stack to build all our customer facing
            software.
          </li>
          {/* <li>Todo: univeersity curriculum.</li> */}
        </ol>
      </ContentBox>
      <ContentBox>
        <Typography variant="h3">flavors and the original form</Typography>
        <p>
          It is fine to substitute one element of the acronym and still call it
          the SJGAR stack. Likewise adding a new element is also perfectly fine.
        </p>
        <ol>
          <li>
            We use the SJGAR stack and added RESTful APIs for our partner
            integrations.
          </li>
          <li>We use the SJGAR stack with Azure.</li>
          <li>We use the SJGAR stack with K8S.</li>
          <li>We use the SJGAR stack with Vue.</li>
        </ol>
        {/* </Paper> */}
      </ContentBox>
      <ContentBox>
        <Typography variant="h3">gettting started</Typography>
        <p>
          <b>Learn: </b>If you are looking to learn more about some of the
          technologies in the SJGAR stack head over to{' '}
          <a href="https://fullstackopen.com/en/">Full stack open Course</a>{' '}
          which hosts an open and free course for learning React, Redux,
          Node.js, MongoDB, GraphQL and TypeScript in one go.
        </p>
        <p>
          <b>Play: </b>If you want to play around with the stack head over to{' '}
          <a href="https://serverless-stack.com/>">Serverless Stack</a>.
          Serverless Stack (SST) is, according to their website, an open-source
          serverless application platform that deploys to your AWS account,
          helping you go from idea to IPO.
        </p>
        {/* <li>Todo: univeersity curriculum.</li> */}
      </ContentBox>
      <Box mt={4}></Box>
      {/* </Container> */}
    </MainBox>
    <Box style={{ backgroundColor: '#efefef' }}>
      <MainBox maxWidth={800}>
        <RelatedTopics />
      </MainBox>
    </Box>
    <Box style={{ backgroundColor: 'white' }}>
      <MainBox>
        <Link to="/privacy-policy/">
          <div style={{ fontSize: 14 }}>Privacy policy</div>
        </Link>
      </MainBox>
    </Box>
  </HeroLayout>

  // MORE READING
  // https://einaregilsson.com/serverless-15-percent-slower-and-eight-times-more-expensive/
  // https://martinfowler.com/articles/serverless.html
  // what about lockin
  // https://news.ycombinator.com/item?id=17378749

  // <Container maxWidth="sm">
  //   <Paper style={{ padding: 32 }}>hi</Paper>
  // </Container>
  /* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */
  // </Layout>
);

const RelatedTopics = () => {
  return (
    <>
      <Typography variant="h2">related topics</Typography>
      <ul>
        <li>
          <Link to="/learn-once-apply-everywhere">
            Learn once, apply everywhere
          </Link>
        </li>
        <li>
          <Link to="/full-stack-engineering">Full stack engineering</Link>
        </li>
        <li>
          <Link to="/reactive-systems">Reactive systems</Link> - Responsive,
          Resilient, Elastic, Message Driven
        </li>
        <li>
          <Link to="/microservices">Microservices</Link>
        </li>
        <li>
          <Link to="/pay-as-you-go">Pay as you go</Link>
        </li>
        <li>
          <Link to="/focusing-on-business-value">
            Focusing on business value
          </Link>
        </li>
        <li>
          <Link to="/startup-and-enterprise-innovation">
            Startup/enterprise innovation
          </Link>
        </li>
      </ul>
      <Typography variant="h2">other links</Typography>
      <ul>
        <li>
          <Link to="/blog/20231102/introducing-sudolite">
            Introducing SudoLite - Pseudocode-Powered Conversations with ChatGPT
          </Link>
        </li>
      </ul>
    </>
  );
};
export default IndexPage;
