import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Link } from 'gatsby';
import useThrottledOnScroll from '../useThrottledOnScroll';

import logo from '../images/sjgar-stack-logo-white.svg';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    backgroundColor: '#039be5',
    transition: 'background-color 1000ms',
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    '& .MuiButton-contained': {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  },
  logo: {
    height: 60,
  },
}));

const Hero = () => {
  const classes = useStyles();

  const [backgroundColor, setBackgroundColor] = useState('#039be5');
  useThrottledOnScroll((e) => {
    if (window.scrollY > 300) {
      setBackgroundColor('#333');
    } else {
      setBackgroundColor('#039be5');
    }
  }, 166);

  console.log({ logo });

  return (
    <Box className={classes.main} style={{ backgroundColor }}>
      <Box style={{ maxWidth: 560 }}>
        <img src={logo} alt="SJGAR stack" className={classes.logo} />

        <Typography
          variant="h2"
          style={{ color: 'white', marginBottom: 48, marginTop: 24 }}
        >
          Focus on your customers, on business value and the developer
          experience. Do more, and keep things simple.
        </Typography>
        <Link
          to="/blog/20201028/introducing-the-sjgar-stack"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">
            <span style={{ textDecoration: 'none' }}>
              Read the introduction post
            </span>
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Hero;
